<template>
  <div>
    <el-card shadow="always">
      <div class="top_label">
        <el-tabs v-model="searchForm.order_status" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="待付款" name="1"></el-tab-pane>
          <el-tab-pane label="待发货" name="2"></el-tab-pane>
          <el-tab-pane label="运输中" name="4"></el-tab-pane>
          <el-tab-pane label="待确认" name="6"></el-tab-pane>
          <el-tab-pane label="待评价" name="7"></el-tab-pane>
        </el-tabs>
      </div>
      <el-table ref="order_info" fit h :data="orderData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
        <el-table-column prop="product_title" label="商品标题" min-width="80"> </el-table-column>
        <el-table-column prop="product_cover_img" label="商品封面" min-width="50">
          <template slot-scope="scope">
            <el-image :src="scope.row.product_cover_img" fit="contain" class="cover_img"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="product_price" label="单价" min-width="30"> </el-table-column>
        <el-table-column prop="buy_count" label="数量" min-width="30"> </el-table-column>
        <el-table-column prop="total_price" label="总价" min-width="30"> </el-table-column>
        <el-table-column prop="order_status" label="订单状态" min-width="30"> </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.need_display"
              size="mini"
              type="danger"
              @click="
                centerDialogVisible = true
                orderOperation(scope.$index, scope.row)
              "
              >{{ scope.row.operation }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          small
        >
        </el-pagination>
      </div>
    </el-card>
    <!--支付弹窗-->
    <div>
      <el-dialog title="支付" :visible.sync="dialogToPayVisible" width="25%" center>
        <div class="pay_dialog">
          <span>您当前账户余额:{{ balance }}</span>
          <span v-if="need_top_up">余额不足，请<span @click="goto_top_up" style="text-decoration: underline;color: #33aef0">前往充值</span></span>
          <span v-if="to_pay">是否确认支付？</span>
        </div>
        <span v-if="to_pay" slot="footer" class="dialog-footer">
          <el-button @click="dialogToPayVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              dialogToPayVisible = false
              pay()
            "
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!--确认收货弹窗-->
    <div>
      <el-dialog title="确认收货" :visible.sync="dialogToConfirmVisible" width="25%" center>
        <span>是否已经收到货？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogToConfirmVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              dialogToConfirmVisible = false
              confirmGoods()
            "
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!--评价弹框-->
    <div>
      <el-dialog title="评价" :visible.sync="dialogToEvaluationVisible" width="25%" center>
        <div class="evaluation">
          <span style="margin-right: 5px">请给卖家评价</span>
          <el-rate v-model="evaluation" show-text> </el-rate>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogToEvaluationVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              dialogToEvaluationVisible = false
              evaluationSeller()
            "
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderBuy',
  data() {
    return {
      evaluation: null,
      total_price: '',
      order_no: '',
      to_pay: true,
      need_top_up: false,
      balance: '',
      dialogToPayVisible: false,
      dialogToConfirmVisible: false,
      dialogToEvaluationVisible: false,
      total: 0,
      currentPage: 1,
      page_size: 10,
      loading: false,
      orderData: [],
      searchForm: {
        order_type: 1,
        order_status: '0'
      }
    }
  },
  created() {
    this.getOrderInfo()
  },
  methods: {
    evaluationSeller() {
      this.$api.order.evaluationSeller({ order_no: this.order_no, evaluation: this.evaluation }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: res.data.msg,
            center: true,
            duration: 2000
          })
          this.getOrderInfo()
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    },
    confirmGoods() {
      this.$api.order.confirmGood({ order_no: this.order_no }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: res.data.msg,
            center: true,
            duration: 2000
          })
          this.getOrderInfo()
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    },
    pay() {
      this.$api.order.pay({ total_price: this.total_price, order_no: this.order_no }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: res.data.msg,
            center: true,
            duration: 2000
          })
          this.getOrderInfo()
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    },
    goto_top_up() {
      this.$router.push('/pay_account_manage')
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.searchProduct()
    },
    handleSizeChange(val) {
      this.page_size = val
      this.searchProduct()
    },
    getBalance(total_price) {
      this.balance = ''
      this.$api.account.getWalletInfo().then(res => {
        this.balance = res.data.data.balance
        if (total_price > this.balance) {
          this.need_top_up = true
          this.to_pay = false
        }
      })
    },
    orderOperation(index, row) {
      this.total_price = ''
      this.order_no = ''
      this.evaluation = null
      if (row.operation === '去支付') {
        this.dialogToPayVisible = true
        this.total_price = row.total_price
        this.order_no = row.order_no
        this.getBalance(row.total_price)
      } else if (row.operation === '去确认收货') {
        this.dialogToConfirmVisible = true
        this.order_no = row.order_no
      } else if (row.operation === '去评价') {
        this.dialogToEvaluationVisible = true
        this.order_no = row.order_no
      } else {
        console.log('失败')
      }
    },
    handleClick() {
      if (this.searchForm.order_status === '0') {
        delete this.searchForm.order_status
      }
      this.getOrderInfo()
      console.log(this.searchForm)
    },
    getOrderInfo() {
      this.loading = true
      this.$api.order.getOrderInfo(this.searchForm).then(res => {
        let resData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
        for (const r in resData) {
          if (resData[r].order_status === 1) {
            resData[r]['operation'] = '去支付'
            resData[r]['order_status'] = '待支付'
            resData[r]['need_display'] = true
          } else if (resData[r].order_status === 2) {
            resData[r]['order_status'] = '待发货'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 3) {
            resData[r]['order_status'] = '已发货'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 4) {
            resData[r]['order_status'] = '运输中'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 5) {
            resData[r]['order_status'] = '已送达'
            resData[r]['need_display'] = false
          } else if (resData[r].order_status === 6) {
            resData[r]['operation'] = '去确认收货'
            resData[r]['order_status'] = '待确认'
            resData[r]['need_display'] = true
          } else if (resData[r].order_status === 7) {
            resData[r]['operation'] = '去评价'
            resData[r]['order_status'] = '待评价'
            resData[r]['need_display'] = true
          } else if (resData[r].order_status === 8) {
            resData[r]['order_status'] = '已完成'
            resData[r]['need_display'] = false
          } else {
            resData[r]['need_display'] = false
          }
        }
        this.orderData = resData
      })
    }
  }
}
</script>

<style scoped>
.cover_img {
  width: 90px;
  height: 90px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.top_label {
  margin-bottom: 10px;
}
.pay_dialog {
  display: flex;
  flex-direction: column;
}
.evaluation {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
